// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import theme from './rtlProvider';
import App from './App';
import './index.css';

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <CSSReset />
    <Router>
      <App />
    </Router>
  </ChakraProvider>,
  document.getElementById('root')
);

