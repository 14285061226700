import React, { useEffect, useState } from 'react';
import { ChakraProvider, Box, Text } from '@chakra-ui/react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { auth, signOut, getUserFromFirestore, getLoanLines } from './firebase';
import Login from './components/Login';
import Navbar from './components/Navbar';
import Customers from './pages/Customers';
import CustomerDetails from './pages/CustomerDetails';
import Reports from './pages/Reports';
import CourierManagement from './components/CourierManagement';
import WeeklyWorksheet from './components/WeeklyWorksheet';
import FinancialCalculations from './components/FinancialCalculations';
import EndOfDay from './components/EndOfDay';
import BusinessCalculations from './components/BusinessCalculations';
import theme from './rtlProvider';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [currentLoanLine, setCurrentLoanLine] = useState(null);
  const [loanLines, setLoanLines] = useState([]);
  const [endOfDayLoans, setEndOfDayLoans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userData = await getUserFromFirestore(user.uid);
        if (userData) {
          const displayName = userData.displayName || user.email.split('@')[0];
          setUser({ ...user, displayName });
          
          const lines = await getLoanLines(user.uid);
          setLoanLines(lines);
          
          const savedLoanLineId = localStorage.getItem('currentLoanLineId');
          if (savedLoanLineId) {
            const savedLoanLine = lines.find(line => line.id === savedLoanLineId);
            if (savedLoanLine) {
              setCurrentLoanLine(savedLoanLine);
            } else if (lines.length > 0) {
              setCurrentLoanLine(lines[0]);
            }
          } else if (lines.length > 0) {
            setCurrentLoanLine(lines[0]);
          }
        }
      } else {
        setUser(null);
        setCurrentLoanLine(null);
        setLoanLines([]);
        localStorage.removeItem('currentLoanLineId');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    await signOut(auth);
    setCurrentLoanLine(null);
    localStorage.removeItem('currentLoanLineId');
    navigate('/');
  };

  const handleLoanLineChange = (loanLine) => {
    setCurrentLoanLine(loanLine);
    localStorage.setItem('currentLoanLineId', loanLine.id);
  };

  const handleMoveToEndOfDay = (loans) => {
    setEndOfDayLoans(prevLoans => [...prevLoans, ...loans]);
  };

  const handleReturnToWorksheet = (loans) => {
    setEndOfDayLoans(prevLoans => prevLoans.filter(loan => !loans.some(l => l.id === loan.id)));
  };

  return (
    <ChakraProvider theme={theme}>
      <Box minHeight="100vh" width="100%" display="flex" flexDirection="column">
        {user ? (
          <>
            <Navbar 
              user={user} 
              handleSignOut={handleSignOut} 
              currentLoanLine={currentLoanLine} 
              setCurrentLoanLine={handleLoanLineChange}
              loanLines={loanLines}
            />
            <Box flex="1" width="100%" p={4}>
              {currentLoanLine ? (
                <Routes>
                  <Route path="/customers" element={<Customers currentLoanLine={currentLoanLine} />} />
                  <Route path="/businesscalculations" element={<BusinessCalculations currentLoanLine={currentLoanLine} />} />
                  <Route path="/customers/:id" element={<CustomerDetails currentLoanLine={currentLoanLine} />} />
                  <Route path="/reports" element={<Reports currentLoanLine={currentLoanLine} />} />
                  <Route path="/couriers" element={<CourierManagement currentLoanLine={currentLoanLine} />} />
                  <Route path="/worksheet" element={
                    <WeeklyWorksheet 
                      currentLoanLine={currentLoanLine} 
                      endOfDayLoans={endOfDayLoans}
                      onMoveToEndOfDay={handleMoveToEndOfDay}
                    />
                  } />
                  <Route path="/calculations" element={<FinancialCalculations currentLoanLine={currentLoanLine} />} />
                  <Route path="/end-of-day" element={
                    <EndOfDay 
                      currentLoanLine={currentLoanLine} 
                      endOfDayLoans={endOfDayLoans}
                      onReturnToWorksheet={handleReturnToWorksheet}
                    />
                  } />
                  <Route path="/" element={<Navigate to="/customers" />} />
                </Routes>
              ) : (
                <Text>אנא בחר קו הלוואות כדי להתחיל</Text>
              )}
            </Box>
          </>
        ) : (
          <Login />
        )}
      </Box>
    </ChakraProvider>
  );
}

export default App;