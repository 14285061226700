import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Heading, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td, 
  Checkbox, 
  Button, 
  VStack,
  HStack,
  useToast,
  Text,
  Spinner
} from '@chakra-ui/react';
import { getLoansByLoanLine } from '../firebase';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';

const allColumns = [
  { label: 'שם', key: 'customerName' },
  { label: 'טלפון', key: 'customerPhone' },
  { label: 'סכום', key: 'amount' },
  { label: 'יורד/עומד', key: 'loanType' },
  { label: 'יתרה', key: 'balance' },
  { label: 'פיגורים', key: 'totalArrears' },
  { label: 'מספר תשלום', key: 'currentPaymentNumber' },
  { label: 'סכום לגבייה', key: 'collectionAmount' },
  { label: 'תאריך תשלום', key: 'nextPaymentDate' },
  { label: 'האם יש פיגור', key: 'hasArrears' },
  { label: 'תאריך פיגור', key: 'arrearsDate' },
  { label: 'תדירות תשלום', key: 'paymentFrequency' },
];

function WeeklyWorksheet({ currentLoanLine, endOfDayLoans, onMoveToEndOfDay }) {
  const [loans, setLoans] = useState([]);
  const [selectedLoans, setSelectedLoans] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(allColumns.map(col => col.key));
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const isDateInCurrentWeek = (date) => {
    const now = new Date();
    const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 6));
    endOfWeek.setHours(23, 59, 59, 999);
    return date >= startOfWeek && date <= endOfWeek;
  };

  const fetchLoans = useCallback(async () => {
    if (currentLoanLine) {
      setIsLoading(true);
      try {
        let fetchedLoans = await getLoansByLoanLine(currentLoanLine.id);
        
        fetchedLoans = fetchedLoans
          .filter(loan => isDateInCurrentWeek(loan.nextPaymentDate.toDate()))
          .filter(loan => !endOfDayLoans.some(eodLoan => eodLoan.id === loan.id))
          .map(loan => ({
            ...loan,
            balance: calculateBalance(loan),
            hasArrears: loan.totalArrears > 0 ? 'כן' : 'לא',
            customerPhone: loan.customerPhone || 'לא זמין',
            paymentFrequency: loan.paymentFrequency === 'weekly' ? 'שבועי' : 'חודשי',
          }));
        
        setLoans(fetchedLoans);
      } catch (error) {
        console.error('Error fetching loans:', error);
        setLoans([]);
        toast({
          title: 'שגיאה בטעינת הלוואות',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setLoans([]);
    }
  }, [currentLoanLine, endOfDayLoans, toast]);

  useEffect(() => {
    fetchLoans();
  }, [fetchLoans]);

  const calculateBalance = (loan) => {
    return parseFloat(loan.amount) - parseFloat(loan.paidAmount);
  };

  const handleCheckboxChange = (loanId) => {
    setSelectedLoans(prev => 
      prev.includes(loanId) 
        ? prev.filter(id => id !== loanId)
        : [...prev, loanId]
    );
  };

  const handleColumnSelect = (columnKey) => {
    setSelectedColumns(prev => 
      prev.includes(columnKey)
        ? prev.filter(key => key !== columnKey)
        : [...prev, columnKey]
    );
  };

  const reverseString = (str) => {
    return str.split('').reverse().join('');
  };

  const generatePDF = () => {
    const doc = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
    });

    doc.addFont('/fonts/OpenSansHebrew-Regular.ttf', 'OpenSansHebrew', 'normal');
    doc.setFont('OpenSansHebrew', 'normal');
    
    const selectedColumnsReversed = [...allColumns]
      .reverse()
      .filter(col => selectedColumns.includes(col.key));
    
    const tableColumn = selectedColumnsReversed.map(col => reverseString(col.label));
    
    const tableRows = loans
      .filter(loan => selectedLoans.includes(loan.id))
      .map(loan => selectedColumnsReversed.map(col => {
        let value = loan[col.key];
        if (col.key === 'nextPaymentDate' || col.key === 'arrearsDate') {
          value = formatDate(value);
        }
        return reverseString(value != null ? value.toString() : '');
      }));

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      theme: 'grid',
      styles: {
        font: 'OpenSansHebrew',
        halign: 'right',
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        minCellWidth: 15
      },
      headStyles: {
        fillColor: [200, 200, 200],
        textColor: [0, 0, 0],
        fontStyle: 'bold',
      },
      columnStyles: {
        0: {cellWidth: 25},
        1: {cellWidth: 25},
        // Adjust other column widths as needed
      },
    });

    doc.save('weekly_worksheet.pdf');
  };

  const moveToEndOfDay = () => {
    const selectedLoansData = loans.filter(loan => selectedLoans.includes(loan.id));
    onMoveToEndOfDay(selectedLoansData);
    setSelectedLoans([]);
    navigate('/end-of-day');
  };

  const formatDate = (date) => {
    if (!date) return '';
    const d = date instanceof Date ? date : date.toDate();
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  if (!currentLoanLine) {
    return <Box>יש לבחור קו הלוואות תחילה</Box>;
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box>
      <Heading mb={4}>דף עבודה שבועי - {currentLoanLine.name}</Heading>
      <VStack spacing={4} align="stretch">
        <HStack flexWrap="wrap">
          {allColumns.map(col => (
            <Checkbox 
              key={col.key} 
              isChecked={selectedColumns.includes(col.key)}
              onChange={() => handleColumnSelect(col.key)}
            >
              {col.label}
            </Checkbox>
          ))}
        </HStack>
        {loans.length > 0 ? (
          <Table>
            <Thead>
              <Tr>
                <Th>בחר</Th>
                {allColumns.filter(col => selectedColumns.includes(col.key)).map(col => (
                  <Th key={col.key}>{col.label}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {loans.map(loan => (
                <Tr key={loan.id}>
                  <Td>
                    <Checkbox 
                      isChecked={selectedLoans.includes(loan.id)}
                      onChange={() => handleCheckboxChange(loan.id)}
                    />
                  </Td>
                  {allColumns.filter(col => selectedColumns.includes(col.key)).map(col => (
                    <Td key={col.key}>
                      {col.key === 'nextPaymentDate' || col.key === 'arrearsDate'
                        ? formatDate(loan[col.key])
                        : loan[col.key]}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text>אין הלוואות לשבוע זה</Text>
        )}
        <HStack>
          <Button 
            onClick={generatePDF} 
            colorScheme="blue" 
            isDisabled={selectedLoans.length === 0 || selectedColumns.length === 0}
          >
            יצא ל-PDF
          </Button>
          <Button 
            onClick={moveToEndOfDay} 
            colorScheme="green" 
            isDisabled={selectedLoans.length === 0}
          >
            העבר לסוף יום
          </Button>
        </HStack>
        {(selectedLoans.length === 0 || selectedColumns.length === 0) && (
          <Text color="red.500">יש לבחור לפחות הלוואה אחת ועמודה אחת לפני ייצוא ל-PDF או העברה לסוף יום</Text>
        )}
      </VStack>
    </Box>
  );
}

export default WeeklyWorksheet;