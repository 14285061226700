import React, { useState } from 'react';
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

function BusinessCalculations() {
  const [businessData, setBusinessData] = useState({
    courierSalary: '',
    advertisingExpenses: '',
    borrowedMoney: '',
    borrowedRate: '',
    monthlyIncome: '',
    otherExpenses: '',
  });
  const [result, setResult] = useState(null);

  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessData(prev => ({ ...prev, [name]: value }));
  };

  const calculateBusinessExpenses = () => {
    const { courierSalary, advertisingExpenses, borrowedMoney, borrowedRate, monthlyIncome, otherExpenses } = businessData;
    const totalExpenses = parseFloat(courierSalary) + parseFloat(advertisingExpenses) + parseFloat(otherExpenses);
    const borrowedCost = parseFloat(borrowedMoney) * (parseFloat(borrowedRate) / 100);
    const netProfit = parseFloat(monthlyIncome) - totalExpenses - borrowedCost;

    setResult({
      courierSalary: parseFloat(courierSalary).toFixed(2),
      advertisingExpenses: parseFloat(advertisingExpenses).toFixed(2),
      otherExpenses: parseFloat(otherExpenses).toFixed(2),
      borrowedMoney: parseFloat(borrowedMoney).toFixed(2),
      borrowedRate: parseFloat(borrowedRate).toFixed(2),
      monthlyIncome: parseFloat(monthlyIncome).toFixed(2),
      totalExpenses: totalExpenses.toFixed(2),
      borrowedCost: borrowedCost.toFixed(2),
      netProfit: netProfit.toFixed(2),
    });
  };

  return (
    <Box p={5}>
      <VStack spacing={5} align="stretch">
        <Heading size="lg">חישובי עסק</Heading>
        <Box bg={bgColor} p={5} borderRadius="md" borderWidth={1} borderColor={borderColor}>
          <FormControl isRequired>
            <FormLabel>משכורת שליח</FormLabel>
            <Input
              type="number"
              name="courierSalary"
              value={businessData.courierSalary}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>הוצאות פרסום</FormLabel>
            <Input
              type="number"
              name="advertisingExpenses"
              value={businessData.advertisingExpenses}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>סכום כסף קנוי</FormLabel>
            <Input
              type="number"
              name="borrowedMoney"
              value={businessData.borrowedMoney}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>אחוז ריבית על כסף קנוי</FormLabel>
            <Input
              type="number"
              name="borrowedRate"
              value={businessData.borrowedRate}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>הכנסה חודשית</FormLabel>
            <Input
              type="number"
              name="monthlyIncome"
              value={businessData.monthlyIncome}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>הוצאות נוספות</FormLabel>
            <Input
              type="number"
              name="otherExpenses"
              value={businessData.otherExpenses}
              onChange={handleInputChange}
            />
          </FormControl>
          <Button mt={6} colorScheme="teal" onClick={calculateBusinessExpenses} width="full">
            חשב
          </Button>
        </Box>
        {result && (
          <Box bg={bgColor} p={5} borderRadius="md" borderWidth={1} borderColor={borderColor}>
            <Heading size="md" mb={4}>תוצאות החישוב</Heading>
            <VStack align="start" spacing={2}>
              <Text><strong>הכנסה חודשית:</strong> ₪{result.monthlyIncome}</Text>
              <Text><strong>משכורת שליח:</strong> ₪{result.courierSalary}</Text>
              <Text><strong>הוצאות פרסום:</strong> ₪{result.advertisingExpenses}</Text>
              <Text><strong>הוצאות נוספות:</strong> ₪{result.otherExpenses}</Text>
              <Text><strong>סכום כסף קנוי:</strong> ₪{result.borrowedMoney}</Text>
              <Text><strong>אחוז ריבית:</strong> {result.borrowedRate}%</Text>
              <Text><strong>סך הכל הוצאות:</strong> ₪{result.totalExpenses}</Text>
              <Text><strong>עלות הכסף הקנוי:</strong> ₪{result.borrowedCost}</Text>
              <Text><strong>רווח נטו:</strong> ₪{result.netProfit}</Text>
            </VStack>
          </Box>
        )}
      </VStack>
    </Box>
  );
}

export default BusinessCalculations;
