import React, { useEffect, useState, useCallback } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, Heading, VStack, Flex, useColorModeValue, Text, Spinner, useToast, Input } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { getCustomersByLoanLine } from '../firebase';
import AddCustomerDrawer from '../components/AddCustomerDrawer';
import { InfoIcon } from '@chakra-ui/icons';

function Customers({ currentLoanLine }) {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const fetchCustomers = useCallback(async () => {
    if (currentLoanLine) {
      setIsLoading(true);
      try {
        const fetchedCustomers = await getCustomersByLoanLine(currentLoanLine.id);
        setCustomers(fetchedCustomers);
        setFilteredCustomers(fetchedCustomers); // Initialize filtered customers
      } catch (error) {
        console.error("Error fetching customers:", error);
        toast({
          title: "שגיאה בטעינת הלקוחות",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setCustomers([]);
      setFilteredCustomers([]);
    }
  }, [currentLoanLine, toast]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    setFilteredCustomers(
      customers.filter(customer =>
        customer.fullName.toLowerCase().includes(lowercasedQuery) ||
        customer.phoneNumber.includes(searchQuery)
      )
    );
  }, [searchQuery, customers]);

  const handleCustomerClick = (customerId) => {
    navigate(`/customers/${customerId}`);
  };

  const bgColor = useColorModeValue("gray.50", "gray.700");
  const hoverBgColor = useColorModeValue("gray.100", "gray.600");

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box p={5}>
      <VStack spacing={5} align="stretch">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading>לקוחות - {currentLoanLine ? currentLoanLine.lineName : 'לא נבחר קו'}</Heading>
          {currentLoanLine && <AddCustomerDrawer currentLoanLine={currentLoanLine} onCustomerAdded={fetchCustomers} />}
        </Flex>
        {currentLoanLine && (
          <Input
            placeholder="חפש לפי שם או מספר טלפון"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            mt={4}
          />
        )}
        {currentLoanLine ? (
          filteredCustomers.length > 0 ? (
            <Table variant="simple">
              <Thead>
                <Tr bg={bgColor}>
                  <Th>שם מלא</Th>
                  <Th>טלפון</Th>
                  <Th>עיר</Th>
                  <Th>פעולות</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredCustomers.map((customer, index) => (
                  <Tr 
                    key={customer.id}
                    bg={index % 2 === 0 ? bgColor : "transparent"}
                    _hover={{ bg: hoverBgColor, transition: "background-color 0.2s" }}
                  >
                    <Td>{customer.fullName}</Td>
                    <Td>{customer.phoneNumber}</Td>
                    <Td>{customer.city}</Td>
                    <Td>
                      <Button
                        leftIcon={<InfoIcon />}
                        colorScheme="blue"
                        variant="outline"
                        size="sm"
                        onClick={() => handleCustomerClick(customer.id)}
                      >
                        כרטיס לקוח
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Text>אין לקוחות בקו זה. לחץ על "הוסף לקוח" כדי להוסיף לקוח חדש.</Text>
          )
        ) : (
          <Text>נא לבחור קו הלוואות כדי להציג את הלקוחות</Text>
        )}
      </VStack>
    </Box>
  );
}

export default Customers;
