import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCustomerById, getLoansByCustomerId, deleteCustomer, deleteLoan, addLoan, updateLoan } from '../firebase';
import { 
  Box, 
  Heading, 
  Text, 
  VStack, 
  HStack, 
  Button, 
  useColorModeValue,
  Grid,
  GridItem,
  Divider,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  useToast,
  Image,
  SimpleGrid,
  Spinner,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon, AddIcon } from '@chakra-ui/icons';
import AddEditLoan from '../components/AddEditLoan';
import AddEditCustomerForm from '../components/AddEditCustomerForm';

const formatDate = (date) => {
  if (!date) return 'תאריך לא זמין';
  return new Date(date.seconds * 1000).toLocaleDateString();
};

function LoanItem({ loan, onUpdateLoan, onDeleteLoan, onEditLoan }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isActive, setIsActive] = useState(loan.isActive);
  const [nextPaymentDate, setNextPaymentDate] = useState(formatDate(loan.nextPaymentDate));

  const handleStatusChange = () => {
    const updatedLoan = { ...loan, isActive: !isActive };
    setIsActive(!isActive);
    onUpdateLoan(updatedLoan);
  };

  const handleNextPaymentDateChange = () => {
    const updatedLoan = { ...loan, nextPaymentDate: new Date(nextPaymentDate) };
    onUpdateLoan(updatedLoan);
    onClose();
  };

  return (
    <Box p={3} borderWidth="1px" borderRadius="md" mb={3}>
      <VStack align="start" spacing={1}>
        <Text><strong>סכום:</strong> {loan.amount} ₪</Text>
        <Text><strong>ריבית:</strong> {loan.interestRate}%</Text>
        <Text><strong>תאריך פתיחה:</strong> {formatDate(loan.startDate)}</Text>
        <Text><strong>תאריך פירעון:</strong> {formatDate(loan.dueDate) || 'תאריך לא זמין'}</Text>
        <HStack>
          <Text><strong>תאריך תשלום הבא:</strong> {formatDate(loan.nextPaymentDate)}</Text>
          <Button size="xs" onClick={onOpen}><EditIcon /></Button>
        </HStack>
        <Text><strong>סכום לגבייה:</strong> {loan.collectionAmount} ₪</Text>
        <HStack>
          <Text><strong>סטטוס:</strong></Text>
          <Switch isChecked={isActive} onChange={handleStatusChange} />
          <Text>{isActive ? 'פעיל' : 'לא פעיל'}</Text>
        </HStack>
      </VStack>

      <HStack mt={2}>
        <Button size="sm" onClick={() => onEditLoan(loan)}>ערוך</Button>
        <Button size="sm" colorScheme="red" onClick={() => onDeleteLoan(loan.id)}>מחק</Button>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>עריכת תאריך תשלום הבא</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>תאריך תשלום הבא</FormLabel>
              <Input 
                type="date" 
                value={nextPaymentDate} 
                onChange={(e) => setNextPaymentDate(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleNextPaymentDateChange}>
              שמור
            </Button>
            <Button variant="ghost" onClick={onClose}>ביטול</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

function CustomerDetails({ currentLoanLine }) {
  const { id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loans, setLoans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const { isOpen: isLoanDrawerOpen, onOpen: onLoanDrawerOpen, onClose: onLoanDrawerClose } = useDisclosure();
  const { isOpen: isEditCustomerDrawerOpen, onOpen: onEditCustomerDrawerOpen, onClose: onEditCustomerDrawerClose } = useDisclosure();
  const [editingLoan, setEditingLoan] = useState(null);
  const toast = useToast();
  
  const fetchCustomerAndLoans = useCallback(async () => {
    setIsLoading(true);
    try {
      const customerData = await getCustomerById(id);
      if (customerData && customerData.loanLineId === currentLoanLine?.id) {
        setCustomer(customerData);
        const loansData = await getLoansByCustomerId(id, currentLoanLine.id);
        setLoans(loansData);
      } else {
        setCustomer(null);
        setLoans([]);
        toast({
          title: 'שגיאה',
          description: 'הלקוח אינו שייך לקו הנוכחי',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error fetching customer data:', error);
      toast({
        title: 'שגיאה',
        description: 'אירעה שגיאה בטעינת פרטי הלקוח',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [id, currentLoanLine, toast]);

  useEffect(() => {
    if (currentLoanLine) {
      fetchCustomerAndLoans();
    }
  }, [currentLoanLine, fetchCustomerAndLoans]);

  const handleEdit = () => {
    onEditCustomerDrawerOpen();
  };

  const handleDelete = async () => {
    if (window.confirm('האם אתה בטוח שברצונך למחוק לקוח זה?')) {
      try {
        await deleteCustomer(id);
        toast({
          title: 'הלקוח נמחק בהצלחה',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/customers');
      } catch (error) {
        console.error('Error deleting customer: ', error);
        toast({
          title: 'שגיאה במחיקת הלקוח',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleAddLoan = () => {
    setEditingLoan(null);
    onLoanDrawerOpen();
  };

  const handleEditLoan = (loan) => {
    setEditingLoan(loan);
    onLoanDrawerOpen();
  };

  const handleDeleteLoan = async (loanId) => {
    if (window.confirm('האם אתה בטוח שברצונך למחוק הלוואה זו?')) {
      try {
        await deleteLoan(loanId);
        setLoans(loans.filter(loan => loan.id !== loanId));
        toast({
          title: 'ההלוואה נמחקה בהצלחה',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error deleting loan: ', error);
        toast({
          title: 'שגיאה במחיקת ההלוואה',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleUpdateLoan = async (updatedLoan) => {
    try {
      await updateLoan(updatedLoan.id, updatedLoan);
      const updatedLoans = loans.map(loan => 
        loan.id === updatedLoan.id ? updatedLoan : loan
      );
      setLoans(updatedLoans);
      toast({
        title: 'הלוואה עודכנה בהצלחה',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating loan:', error);
      toast({
        title: 'שגיאה בעדכון ההלוואה',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSaveLoan = async (loanData) => {
    try {
      let updatedLoan;
      if (editingLoan) {
        updatedLoan = await updateLoan(editingLoan.id, loanData);
      } else {
        updatedLoan = await addLoan({ ...loanData, customerId: id }, currentLoanLine.id);
      }
      await fetchCustomerAndLoans();
      onLoanDrawerClose();
      toast({
        title: `ההלוואה ${editingLoan ? 'עודכנה' : 'נוספה'} בהצלחה`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      return updatedLoan;
    } catch (error) {
      console.error('Error saving loan:', error);
      toast({
        title: 'שגיאה בשמירת ההלוואה',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  const handleCustomerUpdate = (updatedCustomer) => {
    setCustomer(updatedCustomer);
    onEditCustomerDrawerClose();
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!customer || !currentLoanLine) {
    return <Box>הלקוח לא נמצא או אינו שייך לקו הנוכחי</Box>;
  }

  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor}>
      <VStack spacing={6} align="stretch">
        <HStack justifyContent="space-between">
          <Heading as="h2" size="xl">
            פרטי לקוח: {customer.fullName}
          </Heading>
          <HStack>
            <Button leftIcon={<EditIcon />} colorScheme="blue" onClick={handleEdit}>ערוך</Button>
            <Button leftIcon={<DeleteIcon />} colorScheme="red" onClick={handleDelete}>מחק</Button>
          </HStack>
        </HStack>

        <Divider />

        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <GridItem>
            <VStack align="stretch" spacing={3}>
              <Text><strong>מספר טלפון:</strong> {customer.phoneNumber}</Text>
              <Text><strong>עיר:</strong> {customer.city}</Text>
              <Text><strong>כתובת:</strong> {customer.address}</Text>
              <Text><strong>קומה ודירה:</strong> {customer.apartment}</Text>
              <Text><strong>תעודת זהות:</strong> {customer.idNumber}</Text>
            </VStack>
          </GridItem>
          <GridItem>
            <VStack align="stretch" spacing={3}>
              <Text><strong>סטטוס תעסוקה:</strong> {customer.employmentStatus}</Text>
              <Text><strong>שם העסק:</strong> {customer.businessName}</Text>
            </VStack>
          </GridItem>
        </Grid>

        <Divider />

        <Box>
          <Heading as="h3" size="md" mb={2}>ערבונות</Heading>
          <Text>{customer.collaterals || 'אין ערבונות'}</Text>
        </Box>

        <Box>
          <Heading as="h3" size="md" mb={2}>ערבים</Heading>
          <Text>{customer.guarantors || 'אין ערבים'}</Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h3" size="md" mb={2}>מסמכים ותמונות</Heading>
          <SimpleGrid columns={[2, 3, 4]} spacing={4}>
            {customer.fileUrls && customer.fileUrls.map((url, index) => (
              <Image key={index} src={url} alt={`מסמך ${index + 1}`} objectFit="cover" boxSize="150px" />
            ))}
          </SimpleGrid>
        </Box>

        <Divider />

        <Box>
          <Heading as="h3" size="lg" mb={4}>הלוואות</Heading>
          <Button leftIcon={<AddIcon />} colorScheme="green" onClick={handleAddLoan} mb={4}>
            הוסף הלוואה
          </Button>
          {loans.length > 0 ? (
            loans.map((loan) => (
              <LoanItem 
                key={loan.id} 
                loan={loan} 
                onUpdateLoan={handleUpdateLoan}
                onDeleteLoan={handleDeleteLoan}
                onEditLoan={handleEditLoan}
              />
            ))
          ) : (
            <Text>אין הלוואות ללקוח זה</Text>
          )}
        </Box>
      </VStack>

      <Drawer isOpen={isLoanDrawerOpen} placement="right" onClose={onLoanDrawerClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{editingLoan ? 'עריכת הלוואה' : 'הוספת הלוואה'}</DrawerHeader>
          <DrawerBody>
            <AddEditLoan
              loan={editingLoan}
              customerId={id}
              loanLineId={currentLoanLine.id}
              onSave={handleSaveLoan}
              onCancel={onLoanDrawerClose}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer isOpen={isEditCustomerDrawerOpen} placement="right" onClose={onEditCustomerDrawerClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>עריכת פרטי לקוח</DrawerHeader>
          <DrawerBody>
            <AddEditCustomerForm
              onClose={onEditCustomerDrawerClose}
              currentLoanLine={currentLoanLine}
              existingCustomer={customer}
              onSave={handleCustomerUpdate}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default CustomerDetails;