// src/rtlProvider.js
import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
})

const theme = extendTheme({
  direction: 'rtl',
  fonts: {
    heading: 'Rubik, sans-serif',
    body: 'Rubik, sans-serif',
  },
  styles: {
    global: {
      body: {
        direction: 'rtl',
      },
    },
  },
  breakpoints,
})

export default theme