import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { 
  getFirestore, 
  doc, 
  setDoc, 
  getDoc, 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs, 
  updateDoc, 
  deleteDoc,
  Timestamp,
  writeBatch
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyASo9EJB-uOOHTYY3ROt1YKJgNWvuXLlk0",
  authDomain: "loan-786cc.firebaseapp.com",
  projectId: "loan-786cc",
  storageBucket: "loan-786cc.appspot.com",
  messagingSenderId: "36846945629",
  appId: "1:36846945629:web:32d4d9d7eea63a7888d4b4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// User Management
async function addUserToFirestore(user) {
  const userDoc = doc(db, "users", user.uid);
  const userSnapshot = await getDoc(userDoc);

  if (!userSnapshot.exists()) {
    const displayName = user.email.split('@')[0];
    await setDoc(userDoc, {
      email: user.email,
      displayName: displayName,
      isAdmin: false,
      createdAt: Timestamp.now()
    });
  }
}

async function getUserFromFirestore(uid) {
  const userDoc = doc(db, "users", uid);
  const userSnapshot = await getDoc(userDoc);

  if (userSnapshot.exists()) {
    return userSnapshot.data();
  }
  return null;
}

async function isAdmin(uid) {
  const userDoc = doc(db, "users", uid);
  const userSnapshot = await getDoc(userDoc);

  if (userSnapshot.exists()) {
    return userSnapshot.data().isAdmin;
  }
  return false;
}

// Loan Lines
async function getLoanLines(userId) {
  try {
    const q = query(collection(db, 'loanLines'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    throw error;
  }
}

async function createLoanLine(userId, lineData) {
  try {
    const docRef = await addDoc(collection(db, 'loanLines'), {
      userId,
      ...lineData,
      createdAt: Timestamp.now()
    });
    return docRef.id;
  } catch (error) {
    throw error;
  }
}

async function updateLoanLine(loanLineId, lineData) {
  try {
    await updateDoc(doc(db, 'loanLines', loanLineId), {
      ...lineData,
      updatedAt: Timestamp.now()
    });
  } catch (error) {
    throw error;
  }
}

async function deleteLoanLine(loanLineId) {
  try {
    await deleteDoc(doc(db, 'loanLines', loanLineId));
  } catch (error) {
    throw error;
  }
}

// Customers
async function getCustomersByLoanLine(loanLineId) {
  try {
    const q = query(collection(db, 'customers'), where('loanLineId', '==', loanLineId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    throw error;
  }
}

async function getCustomerById(customerId) {
  try {
    const customerDoc = await getDoc(doc(db, 'customers', customerId));
    if (customerDoc.exists()) {
      return { id: customerDoc.id, ...customerDoc.data() };
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching customer: ', error);
    throw error;
  }
}

async function addCustomer(customerData, loanLineId) {
  try {
    const docRef = await addDoc(collection(db, 'customers'), {
      ...customerData,
      loanLineId,
      createdAt: Timestamp.now()
    });
    return docRef.id;
  } catch (error) {
    throw error;
  }
}

async function updateCustomer(customerId, customerData) {
  try {
    await updateDoc(doc(db, 'customers', customerId), {
      ...customerData,
      updatedAt: Timestamp.now()
    });
  } catch (error) {
    throw error;
  }
}

async function deleteCustomer(customerId) {
  try {
    await deleteDoc(doc(db, 'customers', customerId));
  } catch (error) {
    throw error;
  }
}

// Loans
function calculateNextPaymentDate(currentDate, paymentFrequency, paymentDay) {
  let nextDate = new Date(currentDate);
  
  if (paymentFrequency === 'weekly') {
    nextDate.setDate(nextDate.getDate() + 7);
    const targetDay = parseInt(paymentDay);
    while (nextDate.getDay() !== targetDay) {
      nextDate.setDate(nextDate.getDate() + 1);
    }
  } else if (paymentFrequency === 'monthly') {
    nextDate.setMonth(nextDate.getMonth() + 1);
    const targetDay = parseInt(paymentDay);
    nextDate.setDate(Math.min(targetDay, new Date(nextDate.getFullYear(), nextDate.getMonth() + 1, 0).getDate()));
  }
  
  return Timestamp.fromDate(nextDate);
}

async function getLoansByLoanLine(loanLineId, startDate = null, endDate = null) {
  try {
    let q = query(collection(db, 'loans'), where('loanLineId', '==', loanLineId));

    if (startDate && endDate) {
      q = query(q, 
        where('startDate', '>=', startDate),
        where('startDate', '<=', endDate)
      );
    }
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    throw error;
  }
}

async function getLoansByCustomerId(customerId, loanLineId) {
  try {
    const q = query(
      collection(db, 'loans'), 
      where('customerId', '==', customerId),
      where('loanLineId', '==', loanLineId)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    throw error;
  }
}

async function getLoanById(loanId) {
  try {
    const loanDoc = await getDoc(doc(db, 'loans', loanId));
    if (loanDoc.exists()) {
      return { id: loanDoc.id, ...loanDoc.data() };
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
}

async function addLoan(loanData, loanLineId) {
  try {
    if (!loanData.customerId || !loanLineId) {
      throw new Error('Missing customerId or loanLineId');
    }

    const nextPaymentDate = calculateNextPaymentDate(
      new Date(loanData.startDate), 
      loanData.paymentFrequency, 
      loanData.paymentDay
    );

    const loanToAdd = {
      ...loanData,
      loanLineId,
      nextPaymentDate,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      startDate: Timestamp.fromDate(new Date(loanData.startDate)),
      dueDate: loanData.dueDate ? Timestamp.fromDate(new Date(loanData.dueDate)) : null,
      amount: Number(loanData.amount),
      interestRate: Number(loanData.interestRate),
      currentPaymentNumber: Number(loanData.currentPaymentNumber || 1),
      paidAmount: Number(loanData.paidAmount || 0),
      totalArrears: Number(loanData.totalArrears || 0),
      collectionAmount: Number(loanData.collectionAmount),
      balance: Number(loanData.balance || loanData.amount),
      totalPaymentsReceived: Number(loanData.totalPaymentsReceived || 0),
      totalInterestPaid: Number(loanData.totalInterestPaid || 0),
      isActive: true,
    };

    const docRef = await addDoc(collection(db, 'loans'), loanToAdd);

    const newLoan = await getLoanById(docRef.id);
    if (!newLoan) {
      throw new Error('Failed to add loan: Loan not found after creation');
    }

    return newLoan;
  } catch (error) {
    console.error('Error adding loan: ', error);
    throw error;
  }
}

async function updateLoan(loanId, loanData) {
  try {
    const loanToUpdate = {
      ...loanData,
      updatedAt: Timestamp.now(),
    };

    const safeTimestamp = (date) => {
      if (date instanceof Timestamp) return date;
      if (date instanceof Date && !isNaN(date)) return Timestamp.fromDate(date);
      if (typeof date === 'string' || typeof date === 'number') {
        const parsedDate = new Date(date);
        if (!isNaN(parsedDate)) return Timestamp.fromDate(parsedDate);
      }
      console.warn(`Invalid date value: ${date}. Using current date instead.`);
      return Timestamp.now();
    };

    if (loanData.startDate) loanToUpdate.startDate = safeTimestamp(loanData.startDate);
    if (loanData.dueDate) loanToUpdate.dueDate = safeTimestamp(loanData.dueDate);
    if (loanData.nextPaymentDate) loanToUpdate.nextPaymentDate = safeTimestamp(loanData.nextPaymentDate);

    ['amount', 'interestRate', 'currentPaymentNumber', 'paidAmount', 'totalArrears', 'collectionAmount', 'balance', 'totalPaymentsReceived', 'totalInterestPaid'].forEach(field => {
      if (field in loanData) loanToUpdate[field] = Number(loanData[field]);
    });

    if ('isActive' in loanData) loanToUpdate.isActive = Boolean(loanData.isActive);

    await updateDoc(doc(db, 'loans', loanId), loanToUpdate);

    const updatedLoan = await getLoanById(loanId);
    if (!updatedLoan) {
      throw new Error('Failed to update loan: Loan not found after update');
    }

    return updatedLoan;
  } catch (error) {
    console.error('Error updating loan: ', error);
    throw error;
  }
}

async function deleteLoan(loanId) {
  try {
    await deleteDoc(doc(db, 'loans', loanId));
  } catch (error) {
    throw error;
  }
}

async function updateExistingLoansWithNextPaymentDate() {
  try {
    const loansQuery = query(collection(db, 'loans'));
    const querySnapshot = await getDocs(loansQuery);
    
    const batch = writeBatch(db);
    let updateCount = 0;
    
    querySnapshot.forEach((doc) => {
      const loanData = doc.data();
      if (!loanData.nextPaymentDate) {
        const nextPaymentDate = calculateNextPaymentDate(loanData.startDate, loanData.paymentFrequency, loanData.paymentDay);
        batch.update(doc.ref, { nextPaymentDate });
        updateCount++;
      }
    });
    
    if (updateCount > 0) {
      await batch.commit();
    }
  } catch (error) {
    throw error;
  }
}

// Couriers
async function getCouriersByLoanLine(loanLineId) {
  try {
    const q = query(collection(db, 'couriers'), where('loanLineId', '==', loanLineId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    throw error;
  }
}

async function addCourier(courierData, loanLineId) {
  try {
    const docRef = await addDoc(collection(db, 'couriers'), {
      ...courierData,
      loanLineId,
      createdAt: Timestamp.now()
    });
    return docRef.id;
  } catch (error) {
    throw error;
  }
}

async function updateCourier(courierId, courierData) {
  try {
    await updateDoc(doc(db, 'couriers', courierId), {
      ...courierData,
      updatedAt: Timestamp.now()
    });
  } catch (error) {
    throw error;
  }
}

async function deleteCourier(courierId) {
  try {
    await deleteDoc(doc(db, 'couriers', courierId));
  } catch (error) {
    throw error;
  }
}

// File Upload
async function uploadFile(file, path) {
  try {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  } catch (error) {
    throw error;
  }
}

// New functions for Reports
async function getLoansByUserIdAndDateRange(userId, startDate, endDate) {
  try {
    const loanLines = await getLoanLines(userId);
    const loanLineIds = loanLines.map(line => line.id);

    const q = query(
      collection(db, 'loans'),
      where('loanLineId', 'in', loanLineIds),
      where('startDate', '>=', startDate),
      where('startDate', '<=', endDate)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching loans for reports:', error);
    throw error;
  }
}

async function getFinancialSummary(userId, startDate, endDate) {
  try {
    const loans = await getLoansByUserIdAndDateRange(userId, startDate, endDate);
    
    let totalIncome = 0;
    let totalExpenses = 0;

    loans.forEach(loan => {
      totalIncome += loan.paidAmount || 0;
      totalExpenses += loan.expenses || 0;
    });

    return { totalIncome, totalExpenses };
  } catch (error) {
    console.error('Error calculating financial summary:', error);
    throw error;
  }
}

export { 
  auth, 
  db, 
  storage, 
  addUserToFirestore, 
  getUserFromFirestore, 
  isAdmin, 
  signInWithEmailAndPassword, 
  signOut,
  getLoanLines,
  createLoanLine,
  updateLoanLine,
  deleteLoanLine,
  getCustomersByLoanLine,
  getCustomerById,
  addCustomer,
  updateCustomer,
  deleteCustomer,
  getLoansByLoanLine,
  getLoansByCustomerId,
  getLoanById,
  addLoan,
  updateLoan,
  deleteLoan,
  getCouriersByLoanLine,
  addCourier,
  updateCourier,
  deleteCourier,
  uploadFile,
  updateExistingLoansWithNextPaymentDate,
  calculateNextPaymentDate,
  getLoansByUserIdAndDateRange,
  getFinancialSummary
};