import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { db, auth } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function Reports() {
  const [user] = useAuthState(auth);
  const [reportData, setReportData] = useState({
    loans: [],
    income: 0,
    expenses: 0,
    totalLoans: 0,
    totalAmount: 0,
    averageLoan: 0,
    completedLoans: 0,
    activeLoans: 0,
    defaultedLoans: 0,
  });
  const [reportPeriod, setReportPeriod] = useState('all');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');

  useEffect(() => {
    fetchReportData();
  }, [user, reportPeriod, startDate, endDate]);

  const fetchReportData = async () => {
    if (user) {
      const loansQuery = query(collection(db, 'loans'), where('userId', '==', user.uid));
      const querySnapshot = await getDocs(loansQuery);
      
      let loans = [];
      let totalAmount = 0;
      let income = 0;
      let expenses = 0;
      let completedLoans = 0;
      let activeLoans = 0;
      let defaultedLoans = 0;
      
      querySnapshot.forEach((doc) => {
        const loan = { id: doc.id, ...doc.data() };
        if (isLoanInPeriod(loan.startDate, reportPeriod, startDate, endDate)) {
          loans.push(loan);
          totalAmount += loan.amount;
          income += loan.paidAmount;
          expenses += loan.expenses || 0;
          
          if (loan.status === 'completed') completedLoans++;
          else if (loan.status === 'active') activeLoans++;
          else if (loan.status === 'defaulted') defaultedLoans++;
        }
      });
      
      setReportData({
        loans,
        income,
        expenses,
        totalLoans: loans.length,
        totalAmount,
        averageLoan: loans.length > 0 ? totalAmount / loans.length : 0,
        completedLoans,
        activeLoans,
        defaultedLoans,
      });
    }
  };

  const isLoanInPeriod = (loanDate, period, start, end) => {
    const loanDateTime = new Date(loanDate);
    if (period === 'custom') {
      const startDateTime = new Date(start);
      const endDateTime = new Date(end);
      return loanDateTime >= startDateTime && loanDateTime <= endDateTime;
    }
    const now = new Date();
    switch(period) {
      case 'month':
        return now.getMonth() === loanDateTime.getMonth() && now.getFullYear() === loanDateTime.getFullYear();
      case 'year':
        return now.getFullYear() === loanDateTime.getFullYear();
      default:
        return true;
    }
  };

  const handleApplyCustomDates = () => {
    fetchReportData();
  };

  const chartData = {
    labels: reportData.loans.map(loan => new Date(loan.startDate).toLocaleDateString()),
    datasets: [
      {
        label: 'סכום הלוואה',
        data: reportData.loans.map(loan => loan.amount),
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  return (
    <Box p={4} bg={bgColor} color={textColor}>
      <Heading as="h2" size="lg" mb={6}>דוחות</Heading>
      <HStack spacing={4} mb={4}>
        <Select value={reportPeriod} onChange={(e) => setReportPeriod(e.target.value)}>
          <option value="all">כל הזמנים</option>
          <option value="month">חודש נוכחי</option>
          <option value="year">שנה נוכחית</option>
          <option value="custom">טווח מותאם אישית</option>
        </Select>
        {reportPeriod === 'custom' && (
          <>
            <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            <Button onClick={handleApplyCustomDates}>החל</Button>
          </>
        )}
      </HStack>

      <Tabs>
        <TabList>
          <Tab>סיכום</Tab>
          <Tab>הלוואות</Tab>
          <Tab>גרף</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <VStack align="stretch" spacing={4}>
              <HStack justify="space-between">
                <Text>מספר הלוואות כולל:</Text>
                <Text fontWeight="bold">{reportData.totalLoans}</Text>
              </HStack>
              <HStack justify="space-between">
                <Text>סכום הלוואות כולל:</Text>
                <Text fontWeight="bold">{reportData.totalAmount.toFixed(2)} ₪</Text>
              </HStack>
              <HStack justify="space-between">
                <Text>ממוצע הלוואה:</Text>
                <Text fontWeight="bold">{reportData.averageLoan.toFixed(2)} ₪</Text>
              </HStack>
              <HStack justify="space-between">
                <Text>הכנסות:</Text>
                <Text fontWeight="bold" color="green.500">{reportData.income.toFixed(2)} ₪</Text>
              </HStack>
              <HStack justify="space-between">
                <Text>הוצאות:</Text>
                <Text fontWeight="bold" color="red.500">{reportData.expenses.toFixed(2)} ₪</Text>
              </HStack>
              <HStack justify="space-between">
                <Text>רווח נקי:</Text>
                <Text fontWeight="bold" color={(reportData.income - reportData.expenses) > 0 ? "green.500" : "red.500"}>
                  {(reportData.income - reportData.expenses).toFixed(2)} ₪
                </Text>
              </HStack>
              <HStack justify="space-between">
                <Text>הלוואות פעילות:</Text>
                <Text fontWeight="bold">{reportData.activeLoans}</Text>
              </HStack>
              <HStack justify="space-between">
                <Text>הלוואות שהושלמו:</Text>
                <Text fontWeight="bold">{reportData.completedLoans}</Text>
              </HStack>
              <HStack justify="space-between">
                <Text>הלוואות בכשל:</Text>
                <Text fontWeight="bold" color="red.500">{reportData.defaultedLoans}</Text>
              </HStack>
            </VStack>
          </TabPanel>
          <TabPanel>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>תאריך</Th>
                  <Th>לקוח</Th>
                  <Th>סכום</Th>
                  <Th>סטטוס</Th>
                </Tr>
              </Thead>
              <Tbody>
                {reportData.loans.map((loan) => (
                  <Tr key={loan.id}>
                    <Td>{new Date(loan.startDate).toLocaleDateString()}</Td>
                    <Td>{loan.customerName}</Td>
                    <Td>{loan.amount.toFixed(2)} ₪</Td>
                    <Td>{loan.status}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TabPanel>
          <TabPanel>
            <Box height="400px">
              <Line data={chartData} options={{ maintainAspectRatio: false }} />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Reports;