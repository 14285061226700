import React, { useState, useEffect, useCallback } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from '@chakra-ui/react';
import { addLoan, updateLoan, calculateNextPaymentDate, getCustomerById } from '../firebase';

function AddEditLoan({ loan = null, customerId, loanLineId, onSave, onCancel }) {
  const [loanData, setLoanData] = useState({
    amount: '',
    interestRate: '',
    loanType: 'עומדת',
    paymentFrequency: 'weekly',
    paymentDay: '',
    startDate: '',
    dueDate: '',
    currentPaymentNumber: 1,
    paidAmount: 0,
    totalArrears: 0,
    arrearsDate: '',
    nextPaymentDate: '',
    collectionAmount: '',
    customerName: '',
    customerPhone: '',
    balance: '',
    totalPaymentsReceived: 0,
    totalInterestPaid: 0,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const formatDateForInput = useCallback((date) => {
    if (!date) return '';
    let dateObj;
    if (date instanceof Date) {
      dateObj = date;
    } else if (typeof date === 'object' && date.toDate && typeof date.toDate === 'function') {
      dateObj = date.toDate();
    } else if (typeof date === 'string') {
      if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        return date;
      }
      dateObj = new Date(date);
    } else if (typeof date === 'number') {
      dateObj = new Date(date);
    } else {
      console.error('Unsupported date format:', date);
      return '';
    }
    const formattedDate = dateObj.toISOString().split('T')[0];
    return formattedDate;
  }, []);

  const calculateLoanDetails = useCallback(() => {
    const amount = parseFloat(loanData.amount) || 0;
    const interestRate = parseFloat(loanData.interestRate) || 0;
    const paidAmount = parseFloat(loanData.paidAmount) || 0;
    const isStandingLoan = loanData.loanType === 'עומדת';

    let balance = amount;
    let totalInterestPaid = 0;

    if (isStandingLoan) {
      // For standing loans, the balance remains the same
      totalInterestPaid = paidAmount;
    } else {
      // For declining loans, reduce the balance by paid amount
      balance = amount - paidAmount;
      // Simplified interest calculation (you may want to use a more precise method)
      totalInterestPaid = (amount * interestRate / 100) * (paidAmount / amount);
    }

    const totalPaymentsReceived = Math.floor(paidAmount / parseFloat(loanData.collectionAmount || 1));

    setLoanData(prev => ({
      ...prev,
      balance: balance.toFixed(2),
      totalPaymentsReceived,
      totalInterestPaid: totalInterestPaid.toFixed(2),
    }));
  }, [loanData.amount, loanData.interestRate, loanData.paidAmount, loanData.loanType, loanData.collectionAmount]);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      if (customerId) {
        try {
          const customerData = await getCustomerById(customerId);
          if (customerData) {
            setLoanData(prevState => ({
              ...prevState,
              customerName: customerData.fullName,
              customerPhone: customerData.phoneNumber,
            }));
          }
        } catch (error) {
          console.error('Error fetching customer details:', error);
          toast({
            title: 'שגיאה בטעינת פרטי לקוח',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    };

    fetchCustomerDetails();

    if (loan) {
      const formattedLoan = {
        ...loan,
        startDate: formatDateForInput(loan.startDate),
        dueDate: formatDateForInput(loan.dueDate),
        arrearsDate: formatDateForInput(loan.arrearsDate),
        nextPaymentDate: formatDateForInput(loan.nextPaymentDate),
      };
      setLoanData(formattedLoan);
    }
  }, [loan, customerId, formatDateForInput, toast]);

  useEffect(() => {
    calculateLoanDetails();
  }, [calculateLoanDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoanData(prev => ({ ...prev, [name]: value }));
  };

  const handleNumberInputChange = (name, value) => {
    setLoanData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      if (!customerId || !loanLineId) {
        throw new Error('Missing customerId or loanLineId');
      }
      
      const nextPaymentDate = calculateNextPaymentDate(
        new Date(loanData.startDate),
        loanData.paymentFrequency,
        loanData.paymentDay
      );

      calculateLoanDetails(); // Ensure the latest calculations are used

      const loanDataToSave = {
        ...loanData,
        customerId,
        loanLineId,
        nextPaymentDate,
        startDate: loanData.startDate ? new Date(loanData.startDate) : null,
        dueDate: loanData.dueDate ? new Date(loanData.dueDate) : null,
        arrearsDate: loanData.arrearsDate ? new Date(loanData.arrearsDate) : null,
        amount: parseFloat(loanData.amount),
        interestRate: parseFloat(loanData.interestRate),
        currentPaymentNumber: parseInt(loanData.currentPaymentNumber),
        paidAmount: parseFloat(loanData.paidAmount),
        totalArrears: parseFloat(loanData.totalArrears),
        collectionAmount: parseFloat(loanData.collectionAmount),
        balance: parseFloat(loanData.balance),
        totalPaymentsReceived: parseInt(loanData.totalPaymentsReceived),
        totalInterestPaid: parseFloat(loanData.totalInterestPaid),
      };

      if (loan) {
        await updateLoan(loan.id, loanDataToSave);
      } else {
        await addLoan(loanDataToSave);
      }
      
      onSave();
      toast({
        title: `ההלוואה ${loan ? 'עודכנה' : 'נוספה'} בהצלחה`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      toast({
        title: `שגיאה ב${loan ? 'עדכון' : 'הוספת'} ההלוואה`,
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4}>
        <FormControl isRequired>
          <FormLabel>שם הלקוח</FormLabel>
          <Input
            name="customerName"
            value={loanData.customerName}
            isReadOnly
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>טלפון הלקוח</FormLabel>
          <Input
            name="customerPhone"
            value={loanData.customerPhone}
            isReadOnly
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>סכום ההלוואה</FormLabel>
          <NumberInput
            name="amount"
            value={loanData.amount}
            onChange={(valueString) => handleNumberInputChange('amount', valueString)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>ריבית שנתית (%)</FormLabel>
          <NumberInput
            name="interestRate"
            value={loanData.interestRate}
            onChange={(valueString) => handleNumberInputChange('interestRate', valueString)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>סוג הלוואה</FormLabel>
          <Select 
            name="loanType"
            value={loanData.loanType} 
            onChange={handleInputChange}
          >
            <option value="עומדת">עומדת</option>
            <option value="יורדת">יורדת</option>
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>תדירות תשלום</FormLabel>
          <Select 
            name="paymentFrequency"
            value={loanData.paymentFrequency} 
            onChange={handleInputChange}
          >
            <option value="weekly">שבועי</option>
            <option value="monthly">חודשי</option>
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>יום תשלום</FormLabel>
          {loanData.paymentFrequency === 'weekly' ? (
            <Select 
              name="paymentDay"
              value={loanData.paymentDay} 
              onChange={handleInputChange}
            >
              <option value="0">ראשון</option>
              <option value="1">שני</option>
              <option value="2">שלישי</option>
              <option value="3">רביעי</option>
              <option value="4">חמישי</option>
              <option value="5">שישי</option>
              <option value="6">שבת</option>
            </Select>
          ) : (
            <NumberInput
              name="paymentDay"
              value={loanData.paymentDay}
              onChange={(valueString) => handleNumberInputChange('paymentDay', valueString)}
              min={1}
              max={31}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          )}
        </FormControl>
        <FormControl isRequired>
          <FormLabel>תאריך פתיחת ההלוואה</FormLabel>
          <Input 
            name="startDate"
            type="date"
            value={loanData.startDate}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>תאריך פירעון</FormLabel>
          <Input 
            name="dueDate"
            type="date"
            value={loanData.dueDate}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>מספר תשלום נוכחי</FormLabel>
          <NumberInput
            name="currentPaymentNumber"
            value={loanData.currentPaymentNumber}
            onChange={(valueString) => handleNumberInputChange('currentPaymentNumber', valueString)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>סכום ששולם</FormLabel>
          <NumberInput
            name="paidAmount"
            value={loanData.paidAmount}
            onChange={(valueString) => handleNumberInputChange('paidAmount', valueString)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>סך הכל פיגורים</FormLabel>
          <NumberInput
            name="totalArrears"
            value={loanData.totalArrears}
            onChange={(valueString) => handleNumberInputChange('totalArrears', valueString)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>תאריך פיגור</FormLabel>
          <Input 
            name="arrearsDate"
            type="date"
            value={loanData.arrearsDate}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>סכום לגבייה</FormLabel>
          <NumberInput
            name="collectionAmount"
            value={loanData.collectionAmount}
            onChange={(valueString) => handleNumberInputChange('collectionAmount', valueString)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>יתרה</FormLabel>
          <Input
            name="balance"
            value={loanData.balance}
            isReadOnly
          />
        </FormControl>
        <FormControl>
          <FormLabel>מספר תשלומים שהתקבלו</FormLabel>
          <Input
            name="totalPaymentsReceived"
            value={loanData.totalPaymentsReceived}
            isReadOnly
          />
        </FormControl>
        <FormControl>
          <FormLabel>סך הכל ריבית ששולמה</FormLabel>
          <Input
            name="totalInterestPaid"
            value={loanData.totalInterestPaid}
            isReadOnly
          />
        </FormControl>
        <Button 
          type="submit" 
          colorScheme="blue" 
          mr={3} 
          isLoading={isSubmitting} 
          loadingText="שומר..."
        >
          {loan ? 'עדכן' : 'הוסף'} הלוואה
        </Button>
        <Button variant="ghost" onClick={onCancel} isDisabled={isSubmitting}>
          ביטול
        </Button>
      </VStack>
    </form>
  );
}

export default AddEditLoan;