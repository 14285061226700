import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
  useToast,
} from '@chakra-ui/react';

function AddLoanLineModal({ isOpen, onClose, onAddLoanLine }) {
  const [newLine, setNewLine] = useState({ lineName: '', partner: '', partnerShare: 50 });
  const toast = useToast();

  const handleSubmit = () => {
    if (!newLine.lineName.trim()) {
      toast({
        title: "שגיאה",
        description: "יש להזין שם לקו",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    onAddLoanLine(newLine);
    setNewLine({ lineName: '', partner: '', partnerShare: 50 });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>הוסף קו הלוואות חדש</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>שם הקו</FormLabel>
              <Input 
                value={newLine.lineName} 
                onChange={(e) => setNewLine({...newLine, lineName: e.target.value})}
                placeholder="הזן שם לקו החדש"
              />
            </FormControl>
            <FormControl>
              <FormLabel>שם השותף</FormLabel>
              <Input 
                value={newLine.partner} 
                onChange={(e) => setNewLine({...newLine, partner: e.target.value})}
                placeholder="הזן שם השותף (אופציונלי)"
              />
            </FormControl>
            <FormControl>
              <FormLabel>אחוז השותף</FormLabel>
              <NumberInput 
                value={newLine.partnerShare} 
                onChange={(value) => setNewLine({...newLine, partnerShare: parseFloat(value)})}
                min={0} 
                max={100}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            צור קו
          </Button>
          <Button onClick={onClose}>ביטול</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddLoanLineModal;