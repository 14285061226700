import React, { useState, useEffect } from 'react';
import { 
  Box, 
  VStack, 
  Heading, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td, 
  Button, 
  Input, 
  Select,
  useToast,
  Text,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Checkbox,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { updateLoan, calculateNextPaymentDate } from '../firebase';

function EndOfDay({ currentLoanLine, endOfDayLoans, onReturnToWorksheet }) {
  const [loans, setLoans] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    setLoans(endOfDayLoans.map(loan => ({
      ...loan,
      paid: false,
      notPaidReason: '',
      collectionAmount: loan.regularPaymentAmount,
      selected: true, // New field for selecting which loans to update
    })));
  }, [endOfDayLoans]);

  const handlePaidChange = (index, paid) => {
    setLoans(prevLoans => {
      const newLoans = [...prevLoans];
      newLoans[index].paid = paid;
      return newLoans;
    });
  };

  const handleReasonChange = (index, reason) => {
    setLoans(prevLoans => {
      const newLoans = [...prevLoans];
      newLoans[index].notPaidReason = reason;
      return newLoans;
    });
  };

  const handleCollectionAmountChange = (index, amount) => {
    setLoans(prevLoans => {
      const newLoans = [...prevLoans];
      newLoans[index].collectionAmount = amount;
      return newLoans;
    });
  };

  const handleReturnToWorksheet = (index) => {
    const loanToReturn = loans[index];
    onReturnToWorksheet([loanToReturn]);
    setLoans(prevLoans => prevLoans.filter((_, i) => i !== index));
  };

  const handleSelectChange = (index, selected) => {
    setLoans(prevLoans => {
      const newLoans = [...prevLoans];
      newLoans[index].selected = selected;
      return newLoans;
    });
  };

  const handleSubmit = async () => {
    try {
      const loansToUpdate = loans.filter(loan => loan.selected);

      for (const loan of loansToUpdate) {
        if (loan.paid) {
          let nextPaymentDate;
          try {
            console.log('Current nextPaymentDate:', loan.nextPaymentDate);
            console.log('paymentFrequency:', loan.paymentFrequency);
            console.log('paymentDay:', loan.paymentDay);
            
            // Convert nextPaymentDate to a JavaScript Date object
            let currentDate;
            if (loan.nextPaymentDate instanceof Date) {
              currentDate = loan.nextPaymentDate;
            } else if (loan.nextPaymentDate && typeof loan.nextPaymentDate.toDate === 'function') {
              currentDate = loan.nextPaymentDate.toDate();
            } else if (loan.nextPaymentDate && !isNaN(new Date(loan.nextPaymentDate).getTime())) {
              currentDate = new Date(loan.nextPaymentDate);
            } else {
              currentDate = new Date(); // Fallback to current date if nextPaymentDate is invalid
            }
            
            nextPaymentDate = calculateNextPaymentDate(
              currentDate,
              loan.paymentFrequency,
              loan.paymentDay
            );
            
            console.log('Calculated nextPaymentDate:', nextPaymentDate);
          } catch (error) {
            console.error('Error calculating next payment date:', error);
            throw new Error(`Failed to calculate next payment date for loan ${loan.id}: ${error.message}`);
          }

          await updateLoan(loan.id, {
            paidAmount: parseFloat(loan.paidAmount || 0) + parseFloat(loan.collectionAmount),
            currentPaymentNumber: parseInt(loan.currentPaymentNumber || 0) + 1,
            nextPaymentDate,
            lastPaymentDate: new Date()
          });
        } else {
          await updateLoan(loan.id, {
            totalArrears: parseFloat(loan.totalArrears || 0) + parseFloat(loan.collectionAmount),
            arrearsDate: new Date(),
            notPaidReason: loan.notPaidReason
          });
        }
      }

      setLoans(prevLoans => prevLoans.filter(loan => !loan.selected));

      toast({
        title: 'עודכן בהצלחה',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating loans:', error);
      toast({
        title: 'שגיאה בעדכון ההלוואות',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (loans.length === 0) {
    return (
      <Box p={5}>
        <Text>אין הלוואות לעדכון. אנא בחר הלוואות מדף העבודה השבועי.</Text>
        <Button onClick={() => navigate('/worksheet')} mt={4}>חזור לדף העבודה השבועי</Button>
      </Box>
    );
  }

  return (
    <Box p={5}>
      <VStack spacing={5}>
        <Heading>דף סוף יום</Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>בחר</Th>
              <Th>שם הלקוח</Th>
              <Th>סכום לגבייה</Th>
              <Th>שולם</Th>
              <Th>סיבה לאי תשלום</Th>
              <Th>פעולות</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loans.map((loan, index) => (
              <Tr key={loan.id}>
                <Td>
                  <Checkbox 
                    isChecked={loan.selected}
                    onChange={(e) => handleSelectChange(index, e.target.checked)}
                  />
                </Td>
                <Td>{loan.customerName}</Td>
                <Td>
                  <NumberInput
                    value={loan.collectionAmount}
                    onChange={(valueString) => handleCollectionAmountChange(index, valueString)}
                    min={0}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Td>
                <Td>
                  <Select value={loan.paid} onChange={(e) => handlePaidChange(index, e.target.value === 'true')}>
                    <option value={true}>כן</option>
                    <option value={false}>לא</option>
                  </Select>
                </Td>
                <Td>
                  {!loan.paid && (
                    <Input 
                      value={loan.notPaidReason} 
                      onChange={(e) => handleReasonChange(index, e.target.value)}
                      placeholder="סיבה לאי תשלום"
                    />
                  )}
                </Td>
                <Td>
                  <Button onClick={() => handleReturnToWorksheet(index)} size="sm">
                    החזר לדף עבודה
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <HStack spacing={4}>
          <Button colorScheme="blue" onClick={handleSubmit}>שמור ועדכן</Button>
        </HStack>
      </VStack>
    </Box>
  );
}

export default EndOfDay;