import React, { useState } from 'react';
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Select,
  Grid,
  GridItem,
  RadioGroup,
  Radio,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';

function FinancialCalculations() {
  const [loanData, setLoanData] = useState({
    amount: '',
    interestRate: '',
    term: '',
    calculationType: 'simple',
    interestType: 'annual',
    latePaymentDays: '',
    profitPeriod: '6',
  });
  const [result, setResult] = useState(null);

  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoanData(prev => ({ ...prev, [name]: value }));
  };

  const calculateLoan = () => {
    const principal = parseFloat(loanData.amount);
    let rate = parseFloat(loanData.interestRate) / 100;
    const time = parseFloat(loanData.term);

    if (loanData.interestType === 'annual') {
      rate = rate / 12;
    }

    let totalAmount, interest, monthlyPayment;

    if (loanData.calculationType === 'simple') {
      interest = principal * rate * time;
      totalAmount = principal + interest;
      monthlyPayment = totalAmount / time;
    } else {
      const x = Math.pow(1 + rate, time);
      monthlyPayment = (principal * x * rate) / (x - 1);
      totalAmount = monthlyPayment * time;
      interest = totalAmount - principal;
    }

    const latePayment = calculateLatePayment(principal, loanData.interestRate, loanData.latePaymentDays);
    const netProfit = calculateNetProfit(principal, loanData.interestRate, loanData.profitPeriod);
    const decreasingLoan = calculateDecreasingLoan(principal, rate, time);

    setResult({
      monthlyPayment: monthlyPayment.toFixed(2),
      totalAmount: totalAmount.toFixed(2),
      interest: interest.toFixed(2),
      latePayment: latePayment.toFixed(2),
      netProfit: netProfit.toFixed(2),
      decreasingLoan,
    });
  };

  const calculateLatePayment = (amount, rate, days) => {
    return amount * (rate / 100) * (days / 365);
  };

  const calculateNetProfit = (loanAmount, interestRate, period) => {
    const annualInterest = loanAmount * (interestRate / 100);
    return annualInterest * (period / 12);
  };

  const calculateDecreasingLoan = (principal, rate, term) => {
    const monthlyPrincipal = principal / term;
    let totalInterest = 0;
    let remainingPrincipal = principal;

    for (let i = 0; i < term; i++) {
      const monthlyInterest = (remainingPrincipal * rate);
      totalInterest += monthlyInterest;
      remainingPrincipal -= monthlyPrincipal;
    }

    return {
      monthlyPrincipal: monthlyPrincipal.toFixed(2),
      totalInterest: totalInterest.toFixed(2),
      totalPayment: (principal + totalInterest).toFixed(2)
    };
  };

  return (
    <Box p={5}>
      <VStack spacing={5} align="stretch">
        <Heading size="lg">חישובים פיננסיים</Heading>
        <Box bg={bgColor} p={5} borderRadius="md" borderWidth={1} borderColor={borderColor}>
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <GridItem colSpan={2}>
              <FormControl isRequired>
                <FormLabel>סכום ההלוואה</FormLabel>
                <Input 
                  type="number" 
                  name="amount"
                  value={loanData.amount} 
                  onChange={handleInputChange}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>אחוז ריבית</FormLabel>
                <Input 
                  type="number" 
                  name="interestRate"
                  value={loanData.interestRate} 
                  onChange={handleInputChange}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl as="fieldset">
                <FormLabel as="legend">סוג ריבית</FormLabel>
                <RadioGroup 
                  name="interestType"
                  onChange={(value) => setLoanData(prev => ({ ...prev, interestType: value }))} 
                  value={loanData.interestType}
                >
                  <Stack direction="row">
                    <Radio value="annual">שנתית</Radio>
                    <Radio value="monthly">חודשית</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>תקופת ההלוואה (בחודשים)</FormLabel>
                <Input 
                  type="number" 
                  name="term"
                  value={loanData.term} 
                  onChange={handleInputChange}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>סוג חישוב</FormLabel>
                <Select 
                  name="calculationType"
                  value={loanData.calculationType} 
                  onChange={handleInputChange}
                >
                  <option value="simple">ריבית פשוטה</option>
                  <option value="compound">ריבית דריבית</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>ימי פיגור</FormLabel>
                <Input 
                  type="number" 
                  name="latePaymentDays"
                  value={loanData.latePaymentDays} 
                  onChange={handleInputChange}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>תקופת חישוב רווח (בחודשים)</FormLabel>
                <Select 
                  name="profitPeriod"
                  value={loanData.profitPeriod} 
                  onChange={handleInputChange}
                >
                  <option value="6">חצי שנה</option>
                  <option value="12">שנה</option>
                  <option value="18">שנה וחצי</option>
                  <option value="24">שנתיים</option>
                </Select>
              </FormControl>
            </GridItem>
          </Grid>
          <Button mt={6} colorScheme="teal" onClick={calculateLoan} width="full">
            חשב
          </Button>
        </Box>
        {result && (
          <Box bg={bgColor} p={5} borderRadius="md" borderWidth={1} borderColor={borderColor}>
            <Heading size="md" mb={4}>תוצאות החישוב</Heading>
            <VStack align="start" spacing={2}>
              <Text><strong>תשלום חודשי:</strong> ₪{result.monthlyPayment}</Text>
              <Text><strong>סך הכל לתשלום:</strong> ₪{result.totalAmount}</Text>
              <Text><strong>סך הכל ריבית:</strong> ₪{result.interest}</Text>
              <Text><strong>תשלום על פיגור:</strong> ₪{result.latePayment}</Text>
              <Text><strong>רווח נטו לתקופה:</strong> ₪{result.netProfit}</Text>
              <Text><strong>הלוואה יורדת:</strong></Text>
              <Text>- תשלום חודשי קבוע על הקרן: ₪{result.decreasingLoan.monthlyPrincipal}</Text>
              <Text>- סך הכל ריבית: ₪{result.decreasingLoan.totalInterest}</Text>
              <Text>- סך הכל לתשלום: ₪{result.decreasingLoan.totalPayment}</Text>
            </VStack>
          </Box>
        )}
      </VStack>
    </Box>
  );
}

export default FinancialCalculations;