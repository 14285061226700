import React, { useState, useEffect } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  useToast,
  Box,
  Grid,
  GridItem,
  Heading,
  Divider,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
import { db, storage } from '../firebase';
import { collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function AddEditCustomerForm({ onClose, currentLoanLine, existingCustomer = null, onSave }) {
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    city: '',
    address: '',
    apartment: '',
    idNumber: '',
    employmentStatus: 'שכיר',
    businessName: '',
    collaterals: '',
    guarantors: '',
  });
  const [files, setFiles] = useState([]);
  const toast = useToast();

  const bgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  useEffect(() => {
    if (existingCustomer) {
      setFormData(existingCustomer);
    }
  }, [existingCustomer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentLoanLine) {
      toast({
        title: "שגיאה",
        description: "לא נבחר קו הלוואות. אנא בחר קו לפני הוספת או עריכת לקוח.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      let fileUrls = formData.fileUrls || [];
      if (files.length > 0) {
        const newFileUrls = await Promise.all(
          files.map(async (file) => {
            const fileRef = ref(storage, `customerFiles/${currentLoanLine.id}/${Date.now()}_${file.name}`);
            await uploadBytes(fileRef, file);
            return getDownloadURL(fileRef);
          })
        );
        fileUrls = [...fileUrls, ...newFileUrls];
      }

      const customerData = {
        ...formData,
        fileUrls,
        loanLineId: currentLoanLine.id,
        updatedAt: new Date(),
      };

      let updatedCustomer;
      if (existingCustomer) {
        // Update existing customer
        await updateDoc(doc(db, 'customers', existingCustomer.id), customerData);
        updatedCustomer = { ...customerData, id: existingCustomer.id };
        toast({
          title: "הלקוח עודכן בהצלחה",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Add new customer
        customerData.createdAt = new Date();
        const docRef = await addDoc(collection(db, 'customers'), customerData);
        updatedCustomer = { ...customerData, id: docRef.id };
        toast({
          title: "הלקוח נוסף בהצלחה",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      
      onSave(updatedCustomer);
      onClose();
    } catch (error) {
      console.error("Error saving customer: ", error);
      toast({
        title: `שגיאה ב${existingCustomer ? 'עדכון' : 'הוספת'} הלקוח`,
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!currentLoanLine) {
    return <Box><Text>אנא בחר קו הלוואות לפני הוספת או עריכת לקוח.</Text></Box>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={8} align="stretch">
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <GridItem colSpan={2}>
            <Heading size="md">פרטים אישיים</Heading>
            <Divider mt={2} mb={4} />
          </GridItem>
          <GridItem>
            <FormControl isRequired>
              <FormLabel>שם מלא</FormLabel>
              <Input name="fullName" value={formData.fullName} onChange={handleChange} bg={bgColor} borderColor={borderColor} />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired>
              <FormLabel>מספר טלפון</FormLabel>
              <Input name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} bg={bgColor} borderColor={borderColor} />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired>
              <FormLabel>עיר</FormLabel>
              <Input name="city" value={formData.city} onChange={handleChange} bg={bgColor} borderColor={borderColor} />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired>
              <FormLabel>כתובת</FormLabel>
              <Input name="address" value={formData.address} onChange={handleChange} bg={bgColor} borderColor={borderColor} />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>קומה ודירה</FormLabel>
              <Input name="apartment" value={formData.apartment} onChange={handleChange} bg={bgColor} borderColor={borderColor} />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isRequired>
              <FormLabel>מספר ת"ז</FormLabel>
              <Input name="idNumber" value={formData.idNumber} onChange={handleChange} bg={bgColor} borderColor={borderColor} />
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <Heading size="md" mt={6}>פרטי תעסוקה</Heading>
            <Divider mt={2} mb={4} />
          </GridItem>
          <GridItem>
            <FormControl isRequired>
              <FormLabel>סטטוס תעסוקה</FormLabel>
              <Select name="employmentStatus" value={formData.employmentStatus} onChange={handleChange} bg={bgColor} borderColor={borderColor}>
                <option value="שכיר">שכיר</option>
                <option value="עצמאי">עצמאי</option>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>שם העסק / מקום עבודה</FormLabel>
              <Input name="businessName" value={formData.businessName} onChange={handleChange} bg={bgColor} borderColor={borderColor} />
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <Heading size="md" mt={6}>ערבונות וערבים</Heading>
            <Divider mt={2} mb={4} />
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>ערבונות</FormLabel>
              <Textarea 
                name="collaterals" 
                value={formData.collaterals} 
                onChange={handleChange} 
                bg={bgColor} 
                borderColor={borderColor}
                placeholder="פרט את הערבונות כאן..."
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>ערבים</FormLabel>
              <Textarea 
                name="guarantors" 
                value={formData.guarantors} 
                onChange={handleChange} 
                bg={bgColor} 
                borderColor={borderColor}
                placeholder="רשום את פרטי הערבים כאן..."
              />
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel>מסמכים ותמונות</FormLabel>
              <Input
                type="file"
                multiple
                onChange={handleFileChange}
                p={1}
                accept="image/*,.pdf"
              />
            </FormControl>
          </GridItem>
        </Grid>
        <Button type="submit" colorScheme="blue" alignSelf="flex-end" mt={4}>
          {existingCustomer ? 'עדכן לקוח' : 'הוסף לקוח'}
        </Button>
      </VStack>
    </form>
  );
}

export default AddEditCustomerForm;