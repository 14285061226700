// src/components/Login.js
import React, { useState } from 'react';
import { auth, signInWithEmailAndPassword, addUserToFirestore } from '../firebase';
import { Box, Input, Button, Heading, FormControl, FormLabel, useToast } from '@chakra-ui/react';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await addUserToFirestore(user);
      console.log('User logged in successfully');
    } catch (error) {
      console.error('Error logging in:', error);
      toast({
        title: 'שגיאה',
        description: 'אימייל או סיסמה שגויים. אנא נסה שוב.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box width="100%" maxWidth="400px" mx="auto" mt={8}>
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        התחברות
      </Heading>
      <form onSubmit={handleLogin}>
        <FormControl id="email" mb={4}>
          <FormLabel>אימייל</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormControl>
        <FormControl id="password" mb={6}>
          <FormLabel>סיסמה</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </FormControl>
        <Button type="submit" colorScheme="teal" width="full">
          התחבר
        </Button>
      </form>
    </Box>
  );
}

export default Login;
