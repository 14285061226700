import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
  Text,
} from '@chakra-ui/react';
import { db } from '../firebase';
import { collection, addDoc, query, where, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';

function CourierManagement({ currentLoanLine }) {
  const [couriers, setCouriers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [salary, setSalary] = useState('');
  const [editingCourierId, setEditingCourierId] = useState(null);
  const toast = useToast();

  const fetchCouriers = useCallback(async () => {
    if (currentLoanLine) {
      const q = query(collection(db, 'couriers'), where('loanLineId', '==', currentLoanLine.id));
      const querySnapshot = await getDocs(q);
      const couriersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCouriers(couriersData);
    } else {
      setCouriers([]);
    }
  }, [currentLoanLine]);

  useEffect(() => {
    fetchCouriers();
  }, [fetchCouriers]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingCourierId) {
        await updateDoc(doc(db, 'couriers', editingCourierId), {
          name,
          phone,
          salary: parseFloat(salary),
        });
        toast({
          title: 'השליח עודכן בהצלחה',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        await addDoc(collection(db, 'couriers'), {
          name,
          phone,
          salary: parseFloat(salary),
          loanLineId: currentLoanLine.id,
        });
        toast({
          title: 'השליח נוסף בהצלחה',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      setName('');
      setPhone('');
      setSalary('');
      setEditingCourierId(null);
      onClose();
      fetchCouriers();
    } catch (error) {
      console.error('Error adding/updating courier: ', error);
      toast({
        title: 'שגיאה',
        description: 'אירעה שגיאה בהוספת/עדכון השליח',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (courier) => {
    setName(courier.name);
    setPhone(courier.phone);
    setSalary(courier.salary.toString());
    setEditingCourierId(courier.id);
    onOpen();
  };

  const handleDelete = async (id) => {
    if (window.confirm('האם אתה בטוח שברצונך למחוק שליח זה?')) {
      try {
        await deleteDoc(doc(db, 'couriers', id));
        toast({
          title: 'השליח נמחק בהצלחה',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchCouriers();
      } catch (error) {
        console.error('Error deleting courier: ', error);
        toast({
          title: 'שגיאה במחיקת השליח',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  if (!currentLoanLine) {
    return <Box><Text>אנא בחר קו הלוואות כדי לנהל שליחים.</Text></Box>;
  }

  return (
    <Box>
      <Heading as="h2" size="lg" mb={6}>ניהול שליחים - {currentLoanLine.lineName}</Heading>
      <Button onClick={onOpen} colorScheme="teal" mb={4}>הוסף שליח חדש</Button>
      {couriers.length > 0 ? (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>שם</Th>
              <Th>טלפון</Th>
              <Th>משכורת</Th>
              <Th>פעולות</Th>
            </Tr>
          </Thead>
          <Tbody>
            {couriers.map((courier) => (
              <Tr key={courier.id}>
                <Td>{courier.name}</Td>
                <Td>{courier.phone}</Td>
                <Td>{courier.salary} ₪</Td>
                <Td>
                  <Button onClick={() => handleEdit(courier)} mr={2}>ערוך</Button>
                  <Button onClick={() => handleDelete(courier.id)} colorScheme="red">מחק</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text>אין שליחים בקו זה. הוסף שליח חדש כדי להתחיל.</Text>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editingCourierId ? 'ערוך שליח' : 'הוסף שליח חדש'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel>שם</FormLabel>
                <Input value={name} onChange={(e) => setName(e.target.value)} required />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>טלפון</FormLabel>
                <Input value={phone} onChange={(e) => setPhone(e.target.value)} required />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>משכורת</FormLabel>
                <Input type="number" value={salary} onChange={(e) => setSalary(e.target.value)} required />
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              {editingCourierId ? 'עדכן' : 'הוסף'}
            </Button>
            <Button variant="ghost" onClick={onClose}>ביטול</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default CourierManagement;