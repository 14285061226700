import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import AddEditCustomerForm from './AddEditCustomerForm';

function AddCustomerDrawer({ currentLoanLine }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button 
        leftIcon={<AddIcon />} 
        colorScheme="teal" 
        onClick={onOpen}
        isDisabled={!currentLoanLine}
      >
        {currentLoanLine ? 'הוסף לקוח' : 'בחר קו הלוואות תחילה'}
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">הוסף לקוח חדש</DrawerHeader>
          <DrawerBody>
            <AddEditCustomerForm onClose={onClose} currentLoanLine={currentLoanLine} />
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              ביטול
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default AddCustomerDrawer;