import React, { useState, useEffect } from 'react';
import {
  Flex,
  Box,
  Button,
  Heading,
  Spacer,
  useColorMode,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useToast,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  HStack,
  useMediaQuery,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { MoonIcon, SunIcon, ChevronDownIcon, EditIcon, DeleteIcon, HamburgerIcon } from '@chakra-ui/icons';
import { getLoanLines, createLoanLine, updateLoanLine, deleteLoanLine } from '../firebase';
import AddLoanLineModal from './AddLoanLineModal';

function Navbar({ user, handleSignOut, currentLoanLine, setCurrentLoanLine }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue("teal.500", "teal.700");
  const color = useColorModeValue("white", "gray.200");
  const [loanLines, setLoanLines] = useState([]);
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const toast = useToast();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    const fetchLoanLines = async () => {
      if (user) {
        const lines = await getLoanLines(user.uid);
        setLoanLines(lines);
      }
    };
    fetchLoanLines();
  }, [user]);

  const handleAddLoanLine = async (lineData) => {
    try {
      const newLineId = await createLoanLine(user.uid, lineData);
      const newLine = { id: newLineId, ...lineData };
      setLoanLines([...loanLines, newLine]);
      onModalClose();
      toast({
        title: 'קו הלוואה נוסף בהצלחה',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error creating loan line: ', error);
      toast({
        title: 'שגיאה ביצירת קו הלוואה',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditLoanLine = async (lineId, lineData) => {
    try {
      await updateLoanLine(lineId, lineData);
      const updatedLines = loanLines.map(line => 
        line.id === lineId ? { ...line, ...lineData } : line
      );
      setLoanLines(updatedLines);
      if (currentLoanLine && currentLoanLine.id === lineId) {
        setCurrentLoanLine({ ...currentLoanLine, ...lineData });
      }
      toast({
        title: 'קו ההלוואה עודכן בהצלחה',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating loan line: ', error);
      toast({
        title: 'שגיאה בעדכון קו הלוואה',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteLoanLine = async (lineId) => {
    if (window.confirm('האם אתה בטוח שברצונך למחוק קו הלוואה זה?')) {
      try {
        await deleteLoanLine(lineId);
        setLoanLines(loanLines.filter(line => line.id !== lineId));
        if (currentLoanLine && currentLoanLine.id === lineId) {
          setCurrentLoanLine(null);
        }
        toast({
          title: 'קו ההלוואה נמחק בהצלחה',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error deleting loan line: ', error);
        toast({
          title: 'שגיאה במחיקת קו הלוואה',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const NavItems = () => (
    <>
      <Button as={RouterLink} to="/end-of-day" variant="ghost" color={color} mx={2}>
        סוף יום
      </Button>
      <Button as={RouterLink} to="/calculations" variant="ghost" color={color} mx={2}>
        חישובי הלוואות
      </Button>
      <Button as={RouterLink} to="/worksheet" variant="ghost" color={color} mx={2}>
        דף עבודה שבועי
      </Button>
      <Button as={RouterLink} to="/couriers" variant="ghost" color={color} mx={2}>
        שליחים
      </Button>
      <Button as={RouterLink} to="/reports" variant="ghost" color={color} mx={2}>
        דוחות
      </Button>
      <Button as={RouterLink} to="/customers" variant="ghost" color={color} mx={2}>
        לקוחות
      </Button>
      <Button as={RouterLink} to="/businesscalculations" variant="ghost" color={color} mx={2}>
        חישובי עסק
      </Button>
    </>
  );

  return (
    <Flex bg={bgColor} p={4} color={color} alignItems="center" width="100%">
      <Box>
        <Heading size="md">שלום, {user.displayName}</Heading>
      </Box>
      <Spacer />
      {isLargerThan768 ? (
        <HStack>
          <NavItems />
          <Button onClick={toggleColorMode} variant="ghost" color={color} mx={2}>
            {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          </Button>
        </HStack>
      ) : (
        <IconButton
          aria-label="Open menu"
          icon={<HamburgerIcon />}
          onClick={onDrawerOpen}
          variant="outline"
        />
      )}
      <Spacer />
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          {currentLoanLine ? currentLoanLine.lineName : 'בחר קו'}
        </MenuButton>
        <MenuList>
          <MenuItem fontWeight="bold">קווי הלוואות</MenuItem>
          <MenuDivider />
          {loanLines.map((line) => (
            <MenuItem key={line.id}>
              <Flex justifyContent="space-between" alignItems="center" width="100%">
                <Box onClick={() => setCurrentLoanLine(line)}>{line.lineName}</Box>
                <Box>
                  <EditIcon 
                    mr={2} 
                    onClick={(e) => {
                      e.stopPropagation();
                      const newName = prompt('שם חדש לקו ההלוואה:', line.lineName);
                      if (newName && newName !== line.lineName) {
                        handleEditLoanLine(line.id, { lineName: newName });
                      }
                    }} 
                  />
                  <DeleteIcon 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteLoanLine(line.id);
                    }} 
                  />
                </Box>
              </Flex>
            </MenuItem>
          ))}
          <MenuItem onClick={onModalOpen} color="green.500">+ הוסף קו חדש</MenuItem>
        </MenuList>
      </Menu>
      <Button onClick={handleSignOut} colorScheme="teal" variant="outline" ml={4}>
        התנתק
      </Button>
      <AddLoanLineModal isOpen={isModalOpen} onClose={onModalClose} onAddLoanLine={handleAddLoanLine} />

      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>תפריט</DrawerHeader>
          <DrawerBody>
            <VStack align="stretch" spacing={4}>
              <NavItems />
              <Button onClick={toggleColorMode} variant="ghost">
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              </Button>
              <Button onClick={handleSignOut} colorScheme="teal" variant="outline">
                התנתק
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

export default Navbar;